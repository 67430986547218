import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n";
import EmailEnter from "./EmailEnter";
import { RecoilRoot } from "recoil";
import "react-jsonschemapath-form/dist/index.css";
import "qjumpers.common.ui/dist/index.css";
import "styles/bootstrap.overides.scss";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./middleware/apolloClient"; // for seek widget

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <RecoilRoot>
      <Router>
        <EmailEnter />
      </Router>
    </RecoilRoot>
  </ApolloProvider>,
  document.getElementById("root")
);
