import React from "react";
import RouteComponents from "./routes";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import ErrorHandler from "components/common/ErrorHandler";
import { errorState } from "recoil/error/atom";
import { modalState } from "recoil/modal/atom";
import { JL } from "jsnlog";
import ModalComponent from "components/common/ModalComponent";
import { toastMessageState } from "recoil/toast/atom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ToastHandler } from "qjumpers.common.ui";
import environmentConfig from "constants/config";

import 'react-toastify/dist/ReactToastify.css';

function App(props) {
  const error = useRecoilValue(errorState);
  const modal = useRecoilValue(modalState);
  const toast = useRecoilValue(toastMessageState);
  const setToast = useSetRecoilState(toastMessageState);

  const initOptions = {
    onLoad: "login-required",
    checkLoginIframe: false,
    pkceMethod: "S256",
  };

  const eventLogger = (event, error) => {
    
    if (event === "onAuthSuccess") {
      sessionStorage.setItem("lowCreditPopupShown", "false");
      JL.setOptions({
        defaultAjaxUrl: `${environmentConfig.ADMIN.apiHost}/jsnlog.logger`,
        defaultBeforeSend: xhr => {
          // ToDo: need to revisit after swr completion
          // const orgId = getCurrentCompanyId();
          // if (orgId) {
          //   xhr.setRequestHeader("JSNLog-RequestId", orgId);
          // }
        }
      });

      // define a new console for jsnlog
      const console = (oldCons => {
        return {
          log: function(...msg) {
            oldCons.log(...msg);
            JL(`client`).log(...msg);
          },
          table: function(...msg) {
            oldCons.table(...msg);
            JL(`client`).log(...msg);
          },
          info: function(...msg) {
            oldCons.info(...msg);
            JL(`client`).info(...msg);
          },
          warn: function(...msg) {
            oldCons.warn(...msg);
            JL(`client`).warn(...msg);
          },
          error: function(...msg) {
            oldCons.error(...msg);
            JL(`client`).error(...msg);
          }
        };
      })(window.console);
      window.console = console;

      var ajaxAppender = JL.createAjaxAppender("ajaxAppender");
      var consoleAppender = JL.createConsoleAppender("consoleAppender");
      JL(`client`).setOptions({ appenders: [ajaxAppender, consoleAppender] });
    }
  };

  const tokenLogger = (tokens) => {
    localStorage.setItem("qjumpers.adminui.auth.token", tokens.token);
  };

  const clearToastMessage = () => {
    setToast([]);
  };
  let kcLogin = keycloak.login;
  keycloak.login = (options) => {
    options.idpHint = props.idp,
    options.loginHint = props.email;
    kcLogin(options);
  };
  return (
    <div className="App">

      <ReactKeycloakProvider onEvent={eventLogger} onTokens={tokenLogger} initOptions={initOptions} authClient={keycloak}>
        <ErrorHandler errors={error} />
        <ModalComponent modalData={modal} />
        <ToastHandler toast={toast} clearToasts={clearToastMessage} />
        <RouteComponents />
      </ReactKeycloakProvider>
    </div >
  );
}

export default App;
