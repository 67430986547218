import React, { useEffect, useState } from "react";
import "react-jsonschemapath-form/dist/index.css";
import { Route, BrowserRouter as Router } from "react-router-dom";
import "qjumpers.common.ui/dist/index.css";
import config from "constants/config";
import "styles/bootstrap.overides.scss";
import isEmail from "validator/lib/isEmail";
import App from "./App";
import "./i18n";

const EmailEnter = () => {
  const externalLinkRegion = config.defaultCountyCode === "US" ? "us" : "anz";
  const [email, setEmail] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [invalidEmailId, setInvalidEmailId] = useState(false);
  const [idpPresent, setIdpPresent] = useState(false);
  const [idp, setIdp] = useState("");

  useEffect(() => {
    setEnteredEmail("");
  }, []);

  const callSubmitAction = () => {
    if (enteredEmail && isEmail(enteredEmail)) {
      getIdp(enteredEmail);
      setEmail(enteredEmail);
      setInvalidEmailId(false);
    } else {
      setInvalidEmailId(true);
    }
  };

  const submitAction = (e) => {
    callSubmitAction();
  };

  const getHostUrl = () => {
    return config.scheme + "://" + (config.host === "admin_ui_1" ? config.host + ":" + config.port : config.host);
  };

  // const handleRegisterClick = (e) => {
  //   var redirectUrl = getHostUrl();
  //   window.location.href = `${
  //     config.KEYCLOAK.apiHost + config.KEYCLOAK.apiPath
  //   }/realms/qjumpers/protocol/openid-connect/registrations?client_id=qjumpers&redirect_uri=${redirectUrl}/jobs/user/login&response_mode=fragment&response_type=code&scope=openid`;
  // };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      callSubmitAction();
    }
  };

  const getIdp = async (email) => {
    let url = config.ADMIN.apiHost + "/api/EmailCheck?EmailId=" + email;

    fetch(url, {
      method: "GET",
    })
      .then((response) => response.text())
      .then((response) => {
        setIdp(response);
        setIdpPresent(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let token = null;
  if (window.location.href.indexOf("state=") > 0) {
    token = localStorage.getItem("kc-callback-" + window.location.href.split("#")[1].split("&")[0].split("=")[1]);
  }

  const companyName = localStorage.getItem("company-name");

  return (
    <Router>
      {((email && idpPresent) || token || (companyName && !token)) && (
        <Route render={() => <App email={email} idp={idp} />} />
      )}
      {!token && !companyName && (
        <div id="kc-container" className="keycloak-wrapper-container">
          <div>
            <header className="login-pf-header">
              <div id="kc-logo">
                <div id="kc-locale-wrapper" className="">
                  <a id="kc-logo-link" href={getHostUrl()} title="QJumpers ">
                    <div id="kc-logo-wrapper" className="text-center">
                      <span className="logo"></span>
                    </div>
                  </a>
                </div>
              </div>
            </header>
            <div className="bottom-line clearfix"></div>
            <div id="kc-container-wrapper" className=" bg-white">
              <div id="kc-content" className="col-sm-12 col-md-12 col-lg-12 container">
                <div id="kc-content-wrapper" className="">
                  <div id="kc-header" className="login-pf-page-header">
                    <div id="kc-header-wrapper" className="">
                      <h1 className="text-left"> QJumpers Sourcing and Recruitment Software Login</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div id="kc-form" className="col-sm-12 col-md-12 col-lg-12 container">
                <div id="kc-form-wrapper" className="">
                  <div id="kc-form">
                    <div id="kc-form-wrapper">
                      <div className="form-group">
                        <label htmlFor="username" className="form-label label-text">
                          Email
                        </label>

                        <input
                          tabIndex="1"
                          id="username"
                          className="form-control"
                          name="username"
                          value={enteredEmail}
                          type="text"
                          autoFocus=""
                          onKeyDown={handleKeyDown}
                          onChange={(e) => {
                            setEnteredEmail(e.target.value);
                            if (invalidEmailId && isEmail(e.target.value)) {
                              setInvalidEmailId(true);
                            }
                          }}
                          autoComplete="off"
                          aria-invalid=""
                        />
                        {invalidEmailId && (
                          <span
                            id="input-error"
                            className="pf-c-form__helper-text pf-m-error required kc-feedback-text"
                            aria-live="polite">
                            Invalid email.
                          </span>
                        )}
                      </div>

                      <div id="kc-form-buttons" className="form-group">
                        <div className=" col-xs-12 submit text-center form-buttons">
                          <button className="btn btn-success btn-lg" onClick={submitAction}>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="registration-link-info">
                  <p>If you are an employer and your organisation doesnt already have an account with QJumpers, please email <strong>support@qjumpers.com</strong> for a registration link.</p>
                  <p>If your organisation already has a QJumpers account please contact your HR Team for access.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="bottom-line clearfix margin-top"></div>
          <footer className="text-center">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-8 text-left">
                  <ul className="list-unstyled list-inline">
                    <li>
                      <small>
                        <a href="mailto:support@qjumpers.com">support@qjumpers.com</a>
                      </small>
                    </li>
                    <li>
                      <small>
                        <a href="tel:07 927 2777">NZ 07 927 2777/ 0800 758 673</a>
                      </small>
                    </li>
                    <li>
                      <small>
                        <a href="tel:469-606-0992">US 469-606-0992</a>
                      </small>
                    </li>
                    <li>
                      <small>
                        <a
                          id="terms-conditions"
                          href={`https://qjumpers.com/${externalLinkRegion}/terms-conditions/`}
                          target="_blank">
                          Terms &amp; Conditions
                        </a>
                      </small>
                    </li>
                    <li>
                      <small>
                        <a id="ai-talent-sourcing-terms-conditions" href="/AISourcingterms" target="_blank">
                          AI Talent Sourcing Terms &amp; Condition
                        </a>
                      </small>
                    </li>
                    <li>
                      <small>
                        <a
                          id="privacy-statement"
                          href={`https://qjumpers.com/${externalLinkRegion}/privacy-policy/`}
                          target="_blank">
                          Privacy Statement
                        </a>
                      </small>
                    </li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-4 text-right">
                  <span className="logo"></span>
                </div>
              </div>
            </div>
          </footer>
        </div>
      )}
    </Router>
  );
};

export default EmailEnter;
