/*eslint-disable */
const environmentConfigData = !process?.env || process.env.NODE_ENV === "development" ? null : environmentConfig;
/*eslint-enable*/
const config = {
  host: environmentConfigData?.host || "admin_ui_1",
  port: (environmentConfigData && environmentConfigData?.port) || 5005,
  sessionTimeout: (environmentConfigData && environmentConfigData?.sessionTimeout) || 86400000,
  scheme: (environmentConfigData && environmentConfigData?.scheme) || "http",
  stripeApiKey: (environmentConfigData && environmentConfigData?.stripeApiKey) || "pk_test_w7ahZw3KdrM3qpS200X44DUh",
  creditCardSurchargeRate: (environmentConfigData && environmentConfigData?.creditCardSurchargeRate) || 3,
  defaultCountyCode: (environmentConfigData && environmentConfigData?.defaultCountyCode) || "NZ",
  wageScapeSource:
    environmentConfigData?.wageScapeSource || "https://app.sigmacomputing.com/embed/1-5FDUW3M8WwMO3uiweGtY4K",
  ADMIN: {
    apiHost: (environmentConfigData && environmentConfigData?.ADMIN?.apiHost) || "http://admin_api_1:5001",
    apiPath: (environmentConfigData && environmentConfigData?.ADMIN?.apiPath) || "/api",
  },
  GEO: {
    apiHost:
      (environmentConfigData && environmentConfigData?.GEO?.apiHost) || "https://qjumpers-geonames.qa.qjumpers.com",
    apiPath: (environmentConfigData && environmentConfigData?.GEO?.apiPath) || "/api",
  },
  PUBLIC: {
    apiHost: (environmentConfigData && environmentConfigData?.PUBLIC?.apiHost) || "http://public_api_1:5007",
    apiPath: (environmentConfigData && environmentConfigData?.PUBLIC?.apiPath) || "/api",
  },
  REPORT: {
    apiHost: (environmentConfigData && environmentConfigData?.REPORT?.apiHost) || "http://report:5010",
  },
  KEYCLOAK: {
    apiHost: (environmentConfigData && environmentConfigData?.KEYCLOAK?.apiHost) || "http://keycloak:8080",
    apiPath: (environmentConfigData && environmentConfigData?.KEYCLOAK?.apiPath) || "/auth",
    hideIncomplete: JSON.stringify((environmentConfigData && environmentConfigData?.hideIncomplete) || false),
  },
  app: {
    title: "QJumpers",
    website: "http://qjumpers.com",
    helpline: "0800 758 673",
    description: "QJumpers Recruitment",
    head: {
      titleTemplate: "%s - QJumpers",
      meta: [
        { name: "description", content: "QJumpers Recruitment" },
        { charset: "utf-8" },
        { property: "og:site_name", content: "QJumpers Recruitment" },
        { property: "og:image", content: "" },
        { property: "og:locale", content: "en_US" },
        { property: "og:title", content: "QJumpers Recruitment" },
        { property: "og:description", content: "QJumpers Recruitment" },
        { property: "og:card", content: "summary" },
        { property: "og:site", content: "@QJumpers" },
        { property: "og:creator", content: "QJumpers" },
        { property: "og:image:width", content: "200" },
        { property: "og:image:height", content: "200" },
      ],
    },
  },
  DATE_FORMAT: { month: "2-digit", day: "2-digit", year: "numeric" },
  TINYMCE_API_KEY: "glb38a7h8rfmo9h5i4ti42tu2i4tffbqyztahe8yrrsoxhud",
};

export default config;
