import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSetRecoilState } from "recoil";
import { modalState } from "recoil/modal/atom";

const ModalComponent = (props) => {
  const data = props.modalData && props.modalData.length > 0 && props.modalData[0];
  const [show, setShow] = useState(data.show || false);
  const setModalState = useSetRecoilState(modalState);

  useEffect(() => {
    const data = props.modalData && props.modalData.length > 0 && props.modalData[0];
    setShow(data.show);
  }, [props]);

  const hide = () => {
    const modal = props.modalData && props.modalData.length > 0 && props.modalData[0];
    if (modal && modal.onHide) {
      modal.onHide();
    }
    setModalState(() => []);
    setShow(false);
  };
  return (
    <div>
      <Modal show={show} onHide={hide} backdrop={"static"} className={data.className} dialogClassName={data.dialogClassName}>
        <Modal.Header closeButton={data.closeButton} className="brand-primary">
          <Modal.Title>{data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{show ? data.children : null}</Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalComponent;
