import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "./ErrorHandler.module.scss";
import { errorState } from "recoil/error/atom";
import { useSetRecoilState } from "recoil";

const ErrorHandler = (props) => {
  const [error, setError] = useState(null);
  // const [errorIdx, setErrorIdx] = useState()
  const setErrorState = useSetRecoilState(errorState);

  const hideAlert = (callback) => {
    // const { clearErrors } = props
    if (callback) {
      callback();
    }
    setErrorState(() => []);
    // clearErrors(errorIdx)
    setError(null);
    // setErrorIdx(0)
  };

  const getErrorMessageDisplay = (errorDetail) => {
    const errors = [];
    let idx = 0;
    for (var property in errorDetail) {
      if (Array.isArray(errorDetail[property])) {
        errorDetail[property].map((message, i) => errors.push(<p key={idx + "_" + i}> {message} </p>));
      } else if (typeof errorDetail[property] !== "string") {
        errors.push(<p key={idx}> {JSON.stringify(errorDetail[property])} </p>);
      } else {
        errors.push(<p key={idx}> {errorDetail[property]} </p>);
      }
      idx++;
    }
    return errors;
  };

  useEffect(() => {
    const errorIdx = props.errors.length - 1;
    // setErrorIdx(errorIdx)
    setError(props.errors.length ? props.errors[errorIdx] : null);
  }, [props]);

  return error ? (
    <div className={styles.sweetAlert}>
      {!error?.onConfirm ? <SweetAlert 
      type={error.type}
      title={error?.title}
      confirmBtnBsStyle={error.confirmBtnBsStyle || "primary"}
      onConfirm={() => hideAlert(error?.onCancel)} 
      onCancel={() => hideAlert(error?.onCancel)}>
        <span>
          <span>{error.message}</span>
          {error.detail ? (
            <small>
              <br />
              {getErrorMessageDisplay(error.detail)}
            </small>
          ) : null}
        </span>
      </SweetAlert>
      :
      <SweetAlert
        type={error.type}
        title={error.title}
        confirmBtnBsStyle={error.confirmBtnBsStyle || "primary"}
        confirmBtnText={error.confirmBtnText || "OK"}
        onConfirm={() => hideAlert(error?.onConfirm)}
        showCancel={error.showCancel || false}
        cancelBtnBsStyle={error.cancelBtnBsStyle || "default"}
        cancelBtnText={error.cancelBtnText || "Cancel"}
        onCancel={() => hideAlert(error?.onCancel)}>
        <span>
          <span>{error.message}</span>
          {error.detail ? (
            <small>
              <br />
              {getErrorMessageDisplay(error.detail)}
            </small>
          ) : null}
        </span>
      </SweetAlert>
      }
    </div>
  ) : null;
};

export default ErrorHandler;
